import 'lazysizes';
import ScrollMagic from 'scrollmagic';
import Glide from '@glidejs/glide'

new Glide('.glide').mount();


// if (Modernizr.webp) {
//     body = document.getElementsByTagName('body')[0];
//     body.classList.add("mystyle");
// }  


// init controller
// var controller = new ScrollMagic.Controller({globalSceneOptions: {duration: 100}});

// var worshipHeight = document.getElementById('worship').offsetHeight;
// var communityHeight = document.getElementById('community').offsetHeight;
// console.log(worshipHeight);
// console.log(communityHeight);
// new ScrollMagic.Scene({
//     triggerElement: "#worship",
//     // triggerHook: 0,
//     duration: worshipHeight
// })
// .setClassToggle("#worship_bg", "background--active") // add class toggle
// .addTo(controller);
// new ScrollMagic.Scene({
//     triggerElement: "#community",
//     // triggerHook: 0,
//     duration: communityHeight
// })
// .setClassToggle("#community_bg", "background--active") // add class toggle
// .addTo(controller);

(function() {
	let scene;
	// Init ScrollMagic
	let controller = new ScrollMagic.Controller();

	// Run through all sections
	let items = document.querySelectorAll("section");

	items.forEach(function(element) {
		let height = element.clientHeight; //height of current element
        let sectionId = element.id;
		scene = new ScrollMagic.Scene({
			duration: height,
			triggerElement: element,
			triggerHook: 0.6,
			reverse: true
		})
		.on("enter leave", function() {
            // console.log(sectionId);
            var x = document.getElementsByClassName("background");
            var i;
            for (i = 0; i < x.length; i++) {
                x[i].style.opacity = 0;
                // x[i].classList.remove("background--active");
            }
            var $targetId = sectionId+"_bg";
            var z = document.getElementById($targetId);
            // z.classList.add("background--active")
            z.style.opacity = 1;
        })
        // .addIndicators({ name: " - begin " }) // Testing
        .addTo(controller);
	});

	// Scene duration on resize
	// Currently resets page to update duration. Hacky?
	window.addEventListener("resize", function() {
		//window.location.reload(false); // Codepen hates this. Just refreshes page.
	});
	
})();
